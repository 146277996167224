import { Injectable } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { Location } from '@angular/common';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { finalize } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AuthBusinessService {

  constructor(private authService: AuthService, private location: Location, private storage: AngularFireStorage) { }

  forgotPassword(email: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      var actionCodeSettings = {
        url: window.location.origin + "/#/auth/login"
      };
      this.authService.forgotPassword(email, actionCodeSettings)
        .then(() => {
          resolve('Password reset email sent, check your inbox.');
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async resetPassword(code: any, password: any) {
   return new Promise((resolve, reject) => {
    this.authService.resetPassword(code, password).then(() => {
      resolve('Password update successfully.');
    }).catch((error) => {
      reject(error);
    });
   })
  }

  signIn(email: string, password: string) {
    return new Promise((resolve, reject) => {
      this.authService.signIn(email, password).then((res: any) => {
        res.user.getIdToken().then((token: string) => {
          localStorage.setItem('token', token);
        });
        resolve(res);
      }).catch((error: any) => {
        reject(error);
      })
    })
  }
  signUp(email: string, password: string) {
    return new Promise((resolve, reject) => {
      this.authService.signUp(email, password).then((result) => {
        resolve(result.user);
      })
        .catch((error) => {
          reject(error);
        });
    })
  }

  googleLogin() {
    return new Promise((resolve, reject) => {
      this.authService.googleLogin().then((result) => {

        resolve(result.user);
      })
        .catch((error) => {
          reject(error);
        });
    })
  }

  faceBookLogin() {
    return new Promise((resolve, reject) => {
      this.authService.faceBookLogin().then((result) => {
        resolve(result.user);
      })
        .catch((error) => {
          reject(error);
        });
    })
  }

  appleLogin() {
    return new Promise((resolve, reject) => {
      this.authService.appleLogin().then((result) => {
        resolve(result.user);
      })
        .catch((error) => {
          reject(error);
        });
    })
  }

  updateEmail(email: string) {
    return new Promise((resolve, reject) => {
      this.authService.updateEmail(email).then((response) => {
        resolve(response);
      })
        .catch((error) => {
          reject(error);
        });
    })
  }

  deleteUser() {
    return new Promise((resolve, reject) => {
      this.authService.deleteUser().then((response) => {
        resolve(response);
      })
        .catch((error) => {
          reject(error);
        });
    })
  }

  signOut() {
    return new Promise((resolve, reject) => {
      this.authService.signOut().then((response) => {
        resolve(response);
      })
        .catch((error) => {
          reject(error);
        });
    })
  }

  uploadProfileImage(filePath: string, file: any) {
    return new Promise((resolve, reject) => {
      try {
        this.authService.uploadProfileImage(filePath, file)
          .then((downloadUrl) => {
            downloadUrl.subscribe({
              next: (response: any) => {
                resolve(response);
              },
              error: (error: any) => {
                reject(error)
              }
            });
          })
          .catch((error) => {
            reject(error);
          });
      }
      catch (error) {
        reject(error);
      }
    })
  }
}

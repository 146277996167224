import { MappingPair, Profile } from "@dynamic-mapper/mapper";
import { AppointmentListDTO } from "src/app/domain/appointment/appointment-list.dto";
import { AppointmentList } from "../../models/appointment/appointment-list";

export class AppointmentMap extends Profile {
  static readonly DtoToDomain = new MappingPair<AppointmentList,AppointmentListDTO>();

  constructor() {
    super();

    this.createMap(AppointmentMap.DtoToDomain, {
      userId: opt => opt.mapFrom(src => src.userId),
      clientId: opt => opt.mapFrom(src => src.clientId),
      locationId: opt => opt.mapFrom(src => src.locationId),
      stylistId: opt => opt.mapFrom(src => src.stylistId),
      appointmentId: opt => opt.mapFrom(src => src.appointmentId),
      date: opt => opt.mapFrom(src => src.date),
      startTime: opt => opt.mapFrom(src => src.startTime),
      endTime: opt => opt.mapFrom(src => src.endTime),
      stylistPhoneNumber: opt => opt.mapFrom(src => src.stylistPhoneNumber),
      formattedPhoneNumber: opt => opt.mapFrom(src => src.formattedPhoneNumber),
      stylistRecordStatus:opt => opt.mapFrom(src => src.stylistRecordStatus),
      stylistFirstName: opt => opt.mapFrom(src => src.stylistFirstName),
      stylistLastName: opt => opt.mapFrom(src => src.stylistLastName),
      stylistEmail: opt => opt.mapFrom(src => src.stylistEmail),
      recordstatus: opt => opt.mapFrom(src => src.recordstatus),
      isApproved: opt => opt.mapFrom(src => src.isApproved),
      stylist: opt => opt.mapFrom(src => src.stylist),
    })
  }
}

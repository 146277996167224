import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserBusinessService } from 'src/app/core/business/user/user-business.service';
import { PreferenceInput } from 'src/app/core/models/user/preference-input';
import { User } from 'src/app/core/models/user/user';
import { UserInput } from 'src/app/core/models/user/user-input';
import { UserDTO } from 'src/app/domain/user/user.dto';

@Injectable({
  providedIn: 'root'
})
export class UserFacadeService {

  constructor(private userBusinessService: UserBusinessService) { }

  getUser(id:number):Observable<User>{
    return this.userBusinessService.getUser(id);
  }
  updateUser(data:UserInput):Observable<UserInput>{
    return this.userBusinessService.updateUser(data);
  }

  verifyEmailFirebase(authId:string){
    return this.userBusinessService.verifyEmailFirebase(authId);
  }
  getUserByAuth(paramsReq: object){
    return this.userBusinessService.getUserByAuth(paramsReq);
  }

  userDetailsByAuth(authId:string){
    return this.userBusinessService.userDetailsByAuth(authId);
  }

  getPreference(data: PreferenceInput) {
    return this.userBusinessService.getPreference(data);
  }

  getCustomerName(paramsReq: object){
    return this.userBusinessService.getCustomerName(paramsReq);
  }

  createCustomer(data:UserInput):Observable<any>{
    return this.userBusinessService.createCustomer(data);
  }

  getCustomerProfileByUserId(id:number):Observable<User>{
    return this.userBusinessService.getCustomerProfileByUserId(id);
  }

}

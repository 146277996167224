import { MappingPair, Profile } from "@dynamic-mapper/mapper";
import { Location } from "../../models/location/location-list";
import { LocationDTO } from "src/app/domain/location/location.dto";

export class LocationMap extends Profile {
  static readonly DtoToDomain = new MappingPair<Location,LocationDTO>();

  constructor() {
    super();

    this.createMap(LocationMap.DtoToDomain, {
      id: opt => opt.mapFrom(src => src.id),
      images: opt => opt.mapFrom(src => src.images),
      locationName: opt => opt.mapFrom(src => src.locationName),
      address1: opt => opt.mapFrom(src => src.address1),
      address2: opt => opt.mapFrom(src => src.address2),
      state: opt => opt.mapFrom(src => src.state),
      city: opt => opt.mapFrom(src => src.city),
      country: opt => opt.mapFrom(src => src.country),
      pincode: opt => opt.mapFrom(src => src.pincode),
      recordStatus: opt => opt.mapFrom(src => src.recordStatus)
    })
  }
}

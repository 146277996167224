import { Type } from "@angular/core";
import { Profile } from "@dynamic-mapper/mapper";
import { UserProfile } from "./user/user.mappings";
import { ClientMap } from "./client/client.mappings";
import { LocationMap } from "./location/location.mappings";
import { StylistMap } from "./stylist/stylist.mappings";
import { ServiceMap } from "./service/service.mapping";
import { SlotMap } from "./slot/slot.mapping";
import { CategoryMap } from "./service/category.mapping";
import { AppointmentMap } from "./appointment/appointment.mappings";
import { StylistDetailsMap } from "./stylist/stylist-details.mappings";

export const MAPPING_PROFILES: Type<Profile>[] = [
    UserProfile,
    ClientMap,
    LocationMap,
    StylistMap,
    ServiceMap,
    SlotMap,
    CategoryMap,
    AppointmentMap,
    StylistDetailsMap
]

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { EncryptDecrypt } from '../../store/icons/encrypt-decrypt';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivateChild {

  constructor(private router: Router,
    private encryptDecrypt: EncryptDecrypt) { }
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let isLoggedIn = localStorage.getItem('isLoggedIn');
    if (isLoggedIn == 'true') {
      return true;
    }
    this.router.navigate(['/auth/login']);
    return false;
  }

}

import { state } from "@angular/animations";
import { MappingPair, Profile } from "@dynamic-mapper/mapper";
import { id } from "date-fns/locale";
import { StylistDetailsDTO } from "src/app/domain/stylist/stylist-details.dto";
import { StylistDetails } from "../../models/stylist/stylist-details";

export class StylistDetailsMap extends Profile {
  static readonly DtoToDomain = new MappingPair<StylistDetails, StylistDetailsDTO>();

  constructor() {
    super();

    this.createMap(StylistDetailsMap.DtoToDomain, {
      id: opt => opt.mapFrom(src => src.id),
      userId: opt => opt.mapFrom(src => src.userId),
      images: opt => opt.mapFrom(src => src.images),
      about: opt => opt.mapFrom(src => src.about),
      studioName: opt => opt.mapFrom(src => src.studioName),
      studioNumber: opt => opt.mapFrom(src => src.studioNumber),
      studioImage: opt => opt.mapFrom(src => src.studioImage),
      gender: opt => opt.mapFrom(src => src.gender),
      locationId: opt => opt.mapFrom(src => src.locationId),
      firstName: opt => opt.mapFrom(src => src.firstName),
      lastName: opt => opt.mapFrom(src => src.lastName),
      email: opt => opt.mapFrom(src => src.email),
      stdCode: opt => opt.mapFrom(src => src.stdCode),
      smsNumber: opt => opt.mapFrom(src => src.smsNumber),
      clientId: opt => opt.mapFrom(src => src.clientId),
      locationName: opt => opt.mapFrom(src => src.locationName),
      address1: opt => opt.mapFrom(src => src.address1),
      address2: opt => opt.mapFrom(src => src.address2),
      state: opt => opt.mapFrom(src => src.state),
      country: opt => opt.mapFrom(src => src.country),
      city: opt => opt.mapFrom(src => src.city),
      pincode: opt => opt.mapFrom(src => src.pincode),
      camiQRcode: opt => opt.mapFrom(src => src.camiQRcode),
      registerStatus: opt => opt.mapFrom(src => src.registerStatus),
      recordStatus: opt => opt.mapFrom(src => src.recordStatus),
      phoneNumber: opt => opt.mapFrom(src => src.phoneNumber),
      isOpen: opt => opt.mapFrom(src => src.isOpen)
    })
  }
}
import { MappingPair, Profile } from "@dynamic-mapper/mapper";
import { StylistDTO } from "src/app/domain/stylist/stylist.dto";
import { Stylist } from "../../models/stylist/stylist-list";

export class StylistMap extends Profile {
  static readonly DtoToDomain = new MappingPair<Stylist, StylistDTO>();

  constructor() {
    super();

    this.createMap(StylistMap.DtoToDomain, {
      id: opt => opt.mapFrom(src => src.id),
      stylistId: opt => opt.mapFrom(src => src.stylistId),
      images: opt => opt.mapFrom(src => src.images),
      about: opt => opt.mapFrom(src => src.about),
      studioName: opt => opt.mapFrom(src => src.studioName),
      studioNumber: opt => opt.mapFrom(src => src.studioNumber),
      stylistFirstName: opt => opt.mapFrom(src => src.stylistFirstName),
      stylistLastName: opt => opt.mapFrom(src => src.stylistLastName),
      stylistEmail: opt => opt.mapFrom(src => src.stylistEmail),
      stylistPhoneNumber: opt => opt.mapFrom(src => src.stylistPhoneNumber),
      stylistCountryCode: opt => opt.mapFrom(src => src.stylistCountryCode),
      stylistStdCode: opt => opt.mapFrom(src => src.stylistStdCode),
      recordStatus: opt => opt.mapFrom(src => src.recordStatus),
      registerStatus: opt => opt.mapFrom(src => src.registerStatus),
      isOpen: opt => opt.mapFrom(src => src.isOpen),
      stylistSMSNumber:opt=>opt.mapFrom(src=> src.stylistSMSNumber)
    })
  }
}
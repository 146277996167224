import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatBottomSheet, MatBottomSheetConfig, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatMenuTrigger } from '@angular/material/menu';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs';
import { UserFacadeService } from 'src/app/facades/user/user-facade.service';
import { LogoutComponent } from '../../auth-container/logout/logout.component';
import { SharedService } from 'src/app/core/services/shared/shared.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MainLayoutComponent implements OnInit {

  pageTitle!: string;
  selectedIndex: number = 0;
  userId: number;
  profileImage: string = '';
  imgPic: boolean = false;
  showLegend: boolean = false;
  changeIndex: number;
  isFresher: boolean = false;
  showBackArrow: boolean = false;
  getBack: boolean = false;
  previousUrl: any;
  hiddenTitle: any;
  private history: string[] = [];
  @ViewChild(MatMenuTrigger) legends: MatMenuTrigger;
  constructor(
    private location: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userFacadeService: UserFacadeService,
    private bottom: MatBottomSheet,
    private sharedService: SharedService) {
    let id = localStorage.getItem('customerId');
    this.setPageTitle();
    if (id) {
      this.userId = Number(id);
      this.getCustomerDetails();
    } else {
      this.isFresher = true;
    }
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        // Avoid adding duplicates if the same route is visited again
        if (this.history[this.history.length - 1] !== event.urlAfterRedirects) {
          this.history.push(event.urlAfterRedirects);
          this.getBack = this.history.length > 1;        
        }
      });
  }


  getCustomerDetails() {
    try {
      this.userFacadeService.getUser(this.userId).subscribe({
        next: (response: any) => {
          this.isFresher = false;
          // localStorage.setItem('locationId', response.locationId);
          if (this.profileImage == '' || this.profileImage == null) {
            this.imgPic = false;
          } else {
            this.imgPic = true;
            this.profileImage = response.images;
          }
        },
        error: (error) => {
          this.imgPic = false;
        }
      });
    } catch (error: any) {
      this.imgPic = false;
    }
  }

  setPageTitle() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let route = this.activatedRoute.firstChild?.children[0];
        route.data.subscribe((d) => {
          this.hiddenTitle = d["pageTitle"];
        });
        return route;
      }),
      mergeMap((route: any) => route.data)
    ).subscribe((data: any) => {
      let findIndex: any = this.activatedRoute.routeConfig?.children;
      this.pageTitle = data.pageTitle;
      this.showBackArrow = this.getBack;

      if (this.pageTitle == 'Select the Business' || this.pageTitle == "My Booking" || this.pageTitle == "You") {
        this.showBackArrow = false;
      } else {
        this.showBackArrow = this.getBack;
      }
      findIndex.forEach((e: any, i: any) => {
        if (e.data?.pageTitle == this.pageTitle) {
          this.selectedIndex = i - 1;
        } else {
          if (this.pageTitle == "My Booking" || this.pageTitle == "You") { }
          else {
            this.selectedIndex = 0;
          }
          return;
        }
      });
    })
  }
  ngOnInit(): void {
    this.getViewDetails();
  }
  getViewDetails() {
    this.sharedService.getUpdateObservable().subscribe(() => {
      this.getCustomerDetails();
    });
  }
  breadcrumbClick(pageTitle) {
    switch (pageTitle) {
      case 'Home':
        this.selectedIndex = 0;
        this.router.navigate(['/home']);
        break;
      case 'My Booking':
        this.selectedIndex = 1;
        this.router.navigate(['/my-booking']);
        break;
      case 'You':
        this.selectedIndex = 2;
        this.router.navigate(['/settings']);
        break;
    }
  }
  onTabClick(event) {
    // Get the label of the clicked tab.
    const clickedTabLabel = event.target.textContent.trim();
    if (clickedTabLabel != '') {
      this.breadcrumbClick(clickedTabLabel);
    }
  }
  logout() {
    const config: MatBottomSheetConfig = {
      hasBackdrop: true, // Enable backdrop
    };

    const bottomSheetRef: MatBottomSheetRef = this.bottom.open(LogoutComponent, {
      panelClass: 'custom-style',
      ...config,
    });

    bottomSheetRef.afterOpened().subscribe(() => {
      // Listen for backdrop click event and close the bottom sheet
      const backdrop = document.querySelector('.cdk-overlay-backdrop');
      if (backdrop) {
        backdrop.addEventListener('click', () => {
          bottomSheetRef.dismiss();
        });
      }
    });
  }
  //for navigating to edit profile
  viewProfile() {
    this.router.navigate(['/settings']);
  }
  home() {
    this.router.navigate(['/home']);
  }
  back(): void {
    if (this.canGoBack) {
      this.history.pop(); // Remove the current route
      this.showBackArrow = this.history.length > 1;
      this.location.back(); // Navigate back
    }
  }

  get canGoBack(): boolean {
    // Return true if there's more than one route in the history
    return this.history.length > 1;
  }
  hideTitle() {
    if (this.hiddenTitle == 'Profile') {
      return false;
    } else if (this.hiddenTitle == 'Preference') {
      return false;
    } else {
      return true;
    }
  }
}

import { MappingPair, Profile } from "@dynamic-mapper/mapper";
import { SlotDTO } from "src/app/domain/slot/slot.dto";
import { Slot } from "../../models/slot/slot";

export class SlotMap extends Profile {
  static readonly DtoToDomain = new MappingPair<Slot,SlotDTO>();

  constructor() {
    super();

    this.createMap(SlotMap.DtoToDomain, {
    })
  }
}

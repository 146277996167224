import { MappingPair, Profile } from "@dynamic-mapper/mapper";
import { Service } from "../../models/service/service-list";
import { CategoryDTO } from "src/app/domain/service/category.dto";

export class CategoryMap extends Profile {
  static readonly DtoToDomain = new MappingPair<Service,CategoryDTO>();

  constructor() {
    super();

    this.createMap(CategoryMap.DtoToDomain, {
      id: opt => opt.mapFrom(src => src.id),
      images: opt => opt.mapFrom(src => src.images),
      categoryName: opt => opt.mapFrom(src => src.categoryName),
      icon: opt => opt.mapFrom(src => src.icon),
    })
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainLayoutComponent } from './view/main-layout-container/main-layout/main-layout.component';
import { AuthGuard } from './core/guards/auth-guard/auth.guard';
import { PrivacyComponent } from './view/settings-container/privacy/privacy.component';
import { TermsServiceComponent } from './view/settings-container/terms-service/terms-service.component';

const routes: Routes = [
  {
    path: "",
    // canActivateChild: [AuthGuard],
    component: MainLayoutComponent,
    children: [
      {
        path: "",
        redirectTo: "/home",
        pathMatch: "full"
      },
      {
        path: "home",
        data: { pageTitle: 'Home' },
        loadChildren: () => import('src/app/view/home-container/home-container.module').then(m => m.HomeContainerModule)
      },
      {
        path: "my-booking",
        data: { pageTitle: 'My Booking' },
        loadChildren: () => import('src/app/view/booking-container/booking-container.module').then(m => m.BookingContainerModule)
      },
      {
        path: "inbox",
        data: { pageTitle: 'Inbox' },
        loadChildren: () => import('src/app/view/inbox-container/inbox-container.module').then(m => m.InboxContainerModule)
      },
      {
        path: "settings",
        data: { pageTitle: 'You' },
        loadChildren: () => import('src/app/view/settings-container/settings-container.module').then(m => m.SettingsContainerModule)
      },
    ]
  },
  {
    path: "privacy",
    component: PrivacyComponent
  },
  {
    path: "terms",
    component: TermsServiceComponent
  },
  {
    path: "auth",
    loadChildren: () => import("./view/auth-container/auth-container.module").then(m => m.AuthContainerModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule { }

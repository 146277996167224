import { MappingPair, Profile } from "@dynamic-mapper/mapper";
import { ServiceDTO } from "src/app/domain/service/service.dto";
import { Service } from "../../models/service/service-list";

export class ServiceMap extends Profile {
  static readonly DtoToDomain = new MappingPair<Service,ServiceDTO>();

  constructor() {
    super();

    this.createMap(ServiceMap.DtoToDomain, {
      id: opt => opt.mapFrom(src => src.id),
      images: opt => opt.mapFrom(src => src.images),
      mappingImages: opt => opt.mapFrom(src => src.mappingImages),
      about: opt => opt.mapFrom(src => src.about),
      serviceId: opt => opt.mapFrom(src => src.serviceId),
      serviceCategoryId: opt => opt.mapFrom(src => src.serviceCategoryId),
      categoryName: opt => opt.mapFrom(src => src.categoryName),
      serviceName: opt => opt.mapFrom(src => src.serviceName),
      serviceImages: opt => opt.mapFrom(src => src.serviceImages),
      duration: opt => opt.mapFrom(src => src.duration),
      price: opt => opt.mapFrom(src => src.price),
      isSelected: opt => opt.mapFrom(src => src.isSelected),
      recordStatus: opt => opt.mapFrom(src => src.recordStatus)
    })
  }
}

<div class="container">
  <header class="head menu-head">
    <div class="top-header">
      <div class="logo-menu">
        <!-- <img class="logo" src="/assets/images/Mvm2Fav.png" (click)="home()" alt="">
        <h3 class="bread-crumb" (click)="breadcrumbClick(pageTitle)">{{pageTitle}}</h3> -->
        <img style="width: 25px; height: 25px;" src="/assets/images/Mvm2Fav.png" (click)="home()" alt="">
        <p class="logo-title">MYVISITMAKER</p>
      </div>
      <div class="desktop-menu">
      <div class="center">
        <mat-tab-group animationDuration="0ms" class="menu-align" [selectedIndex]="selectedIndex" (click)="onTabClick($event)">
          <mat-tab label="Home">
            <ng-template mat-tab-label>
              <span class="menu-click" (click)="breadcrumbClick('Home')">
                <mat-icon svgIcon="home"></mat-icon>
                <small>Home</small>
              </span>
            </ng-template>
          </mat-tab>
          <mat-tab label="My Booking" *ngIf="!isFresher">
            <ng-template mat-tab-label>
              <span class="menu-click" (click)="breadcrumbClick('My Booking')">
                <mat-icon svgIcon="document"></mat-icon>
                <small>My Booking</small>
              </span>
            </ng-template>
          </mat-tab>
          <mat-tab label="You">
            <ng-template mat-tab-label>
              <span class="menu-click" (click)="breadcrumbClick('You')">
                <mat-icon svgIcon="profile-light"></mat-icon>
                <small>You</small>
              </span>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
    </div>

    <div class="bread-crumbs" *ngIf="hideTitle()">
    <mat-icon svgIcon="arrow-back" *ngIf="showBackArrow" class="back-icon" (click)="back()"></mat-icon>
      <h3 class="bread-crumb" (click)="breadcrumbClick(pageTitle)">{{pageTitle}}</h3>
    </div>
   
  </header>

  <div class="full-container body-container">
    <router-outlet></router-outlet>
  </div>
  <footer class="footer-align">
    <div class="center">
      <mat-tab-group animationDuration="0ms" class="menu-align" [selectedIndex]="selectedIndex" (click)="onTabClick($event)">
        <mat-tab label="Home">
          <ng-template mat-tab-label>
            <span class="menu-click" (click)="breadcrumbClick('Home')">
              <mat-icon svgIcon="home"></mat-icon>
              <small>Home</small>
            </span>
          </ng-template>
        </mat-tab>
        <mat-tab label="My Booking" *ngIf="!isFresher">
          <ng-template mat-tab-label>
            <span class="menu-click" (click)="breadcrumbClick('My Booking')">
              <mat-icon svgIcon="document"></mat-icon>
              <small>My Booking</small>
            </span>
          </ng-template>
        </mat-tab>
        <!-- <mat-tab label="Inbox">
          <ng-template mat-tab-label>
            <span class="menu-click" (click)="breadcrumbClick('Inbox')">
              <mat-icon svgIcon="chat-light"></mat-icon>
              <small>Inbox</small>
            </span>
          </ng-template>
        </mat-tab> -->
        <mat-tab label="You">
          <ng-template mat-tab-label>
            <span class="menu-click" (click)="breadcrumbClick('You')">
              <mat-icon svgIcon="profile-light"></mat-icon>
              <small>You</small>
            </span>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </footer>
</div>

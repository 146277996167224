<header>
  <div class="logo-menu"><img src="/assets/images/Mvm2Fav.png" alt="" class="logo-img"><p class="logo-title">MYVISITMAKER</p></div>
</header>
<!-- <span class="settings-back"> -->

<!-- </span> -->
<div class="body-align">
  <div class="body-heading">
    <h1>Terms of Services</h1>
  </div>
</div>
<div class="body-content">

  <p>Please carefully read and understand the following terms and conditions ("Terms") before using our MYVISITMAKER
    ("App"). By using the App, you agree to comply with and be bound by these Terms. If you do not agree to these Terms,
    please do not use the App.</p>

  <h2>1. Acceptance of Terms</h2>
  <p>By using our App, you acknowledge that you have read, understood, and agreed to be bound by these Terms, as well as
    our Privacy Policy.</p>

  <h2>2. User Registration</h2>
  <p>To use the App, you may be required to create an account. You agree to provide accurate and up-to-date information
    during the registration process.</p>

  <h2>3. Booking and Appointments</h2>
  <p>
    a. The App allows you to book salon appointments with participating salons.<br>
    b. You must provide accurate information when booking appointments, including the type of service and preferred date
    and time.<br>
    c. The availability of appointments is subject to the salon's schedule.
  </p>

  <h2>4. Cancellation and Rescheduling</h2>
  <p>
    a. You can cancel or reschedule appointments through the App, subject to the salon's cancellation policy.<br>
    b. Cancellation fees may apply as per the salon's policy.
  </p>

  <h2>5. Notifications</h2>
  <p>
    a. By using the App, you consent to receive SMS and email notifications related to your appointments, account
    updates, and promotions.<br>
    b. You can manage your notification preferences within the App settings.<br>
    c. SMS charges may apply for receiving the notifications based on the geographical location.
  </p>

  <h2>6. Payment</h2>
  <p>
    a. Some salons may require payment through the App for services. You agree to pay all charges associated with your
    bookings.<br>
    b. Payment information is securely processed in accordance with our Privacy Policy.
  </p>

  <h2>7. User Conduct</h2>
  <p>
    a. You agree not to use the App for any unlawful or unauthorized purpose.<br>
    b. You shall not interfere with the operation of the App, including attempting to gain unauthorized access to our
    systems.
  </p>

  <h2>8. Termination</h2>
  <p>We reserve the right to suspend or terminate your access to the App at our discretion if you violate these Terms or
    engage in any fraudulent or abusive activities.</p>

  <h2>9. Privacy</h2>
  <p>Your use of the App is subject to our Privacy Policy, which governs the collection, use, and sharing of your
    personal information.</p>

  <h2>10. Liability</h2>
  <p>
    a. We are not responsible for any services provided by salons, including their quality, safety, or availability.<br>
    b. We are not liable for any losses, damages, or disputes arising from your use of the App or salon services.
  </p>

  <h2>11. Modifications</h2>
  <p>We reserve the right to modify, suspend, or terminate the App or these Terms at any time without notice. It is your
    responsibility to review these Terms periodically for updates.</p>

  <h2>12. Governing Law</h2>
  <p>These Terms are governed by and construed in accordance with the substantive laws of the State of Delaware, USA
    without regard to conflict of laws, and any disputes shall be subject to the exclusive jurisdiction of the courts in
    the State Court located in Delaware, USA.</p>

  <h2>13. Contact Us</h2>
  <p>If you have any questions, concerns, or feedback regarding the App or these Terms, please contact us at <a
      href="mailto:support@bezohminds.com">support@bezohminds.com</a>.</p>

  <p>By using the Salon Booking App, you acknowledge that you have read, understood, and accepted these Terms and agree
    to abide by them.</p>

</div>
<!-- <hr> -->
<footer>

</footer>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonHelperService } from 'src/app/core/utilities/common-helper/common-helper.service';

@Component({
  selector: 'app-terms-service',
  templateUrl: './terms-service.component.html',
  styleUrls: ['./terms-service.component.scss']
})
export class TermsServiceComponent implements OnInit {
  darkMode: boolean;
  isMobile: any;

  constructor( private commonHelper: CommonHelperService, private activatedRouter: ActivatedRoute, private router: Router,) {
    this.darkMode = this.commonHelper.isDarkEnabled();
    this.isMobile = this.activatedRouter.snapshot.queryParamMap.get('isMobile');
  }

  ngOnInit(): void {
  }

  back(){
    this.router.navigate(['/settings']);
  }
}

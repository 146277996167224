import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { LooseObject } from '../../models/common/loose-object.model';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return from(this.handleAccess(request, next));
    // return next.handle(request);
  }

  private async handleAccess(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {

    //Determine which Endpoints requires authentication
    const isAuthRequired = this.isAuthRequired(request.url.toString());

    //Determine which kind of content type required for endpoints
    const dataType = this.setDataType(request.url.toString(),request);

    let headers: LooseObject = {};
    let params = request.params;

    if (isAuthRequired) {
      const _token = await this.getApiToken();
      // headers["Authorization"] = "Bearer " + _token;
    }

    let reqCloneOptions = {};

    switch (dataType) {
      case "text":
        headers["Content-Type"] = "text/plain";
        break;
      case "byte":
        headers["Content-Type"] = "application/octet-stream";
        break;
      case "xml":
        headers["Content-Type"] = "application/xml";
        break;
      case "csv":
        headers["Content-Type"] = "text/csv";
        break;
      case "binary":
        headers["Content-Type"] = undefined; // let it be auto
        break;
      case "cloud-event":
        headers["Content-Type"] = "application/cloudevents+json";
        break;
      default:
        headers["Content-Type"] = "application/json";
        break;
    }

    const requestHeaders = this.getRequestHeaders(request);
    headers = Object.assign(requestHeaders, headers);
    reqCloneOptions = {
      headers: new HttpHeaders(headers),
      params: params,
    }
    let reqClone = request.clone(reqCloneOptions);
    return next.handle(reqClone).toPromise() as any;
  }

  private getRequestHeaders(request: HttpRequest<any>) {
    const headerKeys = request.headers.keys();
    let requestHeaders: LooseObject = {};
    headerKeys.forEach((key: string) => {
      requestHeaders[key] = request.headers.get(key);
    });
    return requestHeaders;
  }

  private setDataType(requestUrl: string, request: HttpRequest<any>) {
    let type = "";
    switch (true) {
      // case requestUrl.indexOf("authentication") > 0:
      //   type = "json";
      //   break;
      default:
        type = "json";
        break;
    }
    return type;
  }

  private isAuthRequired(request: string) {
    let isNeeded: boolean;
    switch (true) {
      // case request.indexOf("authentication") > 0:
      //   isNeeded = true;
      //   break;
      default:
        isNeeded = true;
        break;
    }
    return isNeeded;
  }
  getApiToken(){
    const token= localStorage.getItem('token');
    return token;
  }

}

import { Injectable } from '@angular/core';
import { UserService } from '../../services/user/user.service';
import { Observable } from 'rxjs';
import { User } from '../../models/user/user';
import { CommonHelperService } from '../../utilities/common-helper/common-helper.service';
import { UserInput } from '../../models/user/user-input';
import { PreferenceInput } from '../../models/user/preference-input';

@Injectable({
  providedIn: 'root'
})
export class UserBusinessService {

  constructor(
    private userService: UserService,
    private commonHelper: CommonHelperService
  ) { }

  getUser(id: number): Observable<User> {
    return new Observable((observer) => {
      this.userService.getUser(id).subscribe({
        next: (response: User) => {
          try {
            // if(response.length!=0){
              observer.next(response)
            // }else{
            //   observer.error('Invalid URL');
            // }
          }
          catch (e) {
            observer.error(e);
          }
        },
        error: (error) => {
          observer.error(error);
        }
      })
    })
  }

  updateUser(data: UserInput): Observable<UserInput> {
    return new Observable((observer) => {
      this.userService.updateUser(data).subscribe({
        next: (response) => {
          try {
            observer.next(response)
          }
          catch (e) {
            observer.error(e);
          }
        },
        error: (error) => {
          observer.error(error);
        }
      })
    })
  }
  getUserByAuth(paramsReq: object){
    return new Observable((observer) => {
      this.userService.getUserByAuth(paramsReq).subscribe({
        next: (response) => {
          observer.next(response);
        },
        error: ((error) => {
          observer.error(error);
        })
      });
    })
  }

  verifyEmailFirebase(authId: string): Observable<string> {
    return new Observable((observer) => {
      this.userService.verifyEmailFirebase(authId).subscribe({
        next: (response) => {
          try {
            observer.next(response)
          }
          catch (e) {
            observer.error(e);
          }
        },
        error: (error) => {
          observer.error(error);
        }
      })
    })
  }

  userDetailsByAuth(authId: string): Observable<string> {
    return new Observable((observer) => {
      this.userService.userDetailsByAuth(authId).subscribe({
        next: (response) => {
          try {
            observer.next(response)
          }
          catch (e) {
            observer.error(e);
          }
        },
        error: (error) => {
          observer.error(error);
        }
      })
    })
  }

  /**
   * This API function is used to update the preference toggle value.
   * @param data object
   * @returns
   */
  getPreference(data: PreferenceInput) {
    return new Observable((observer) => {
      this.userService.getPreference(data). subscribe({
        next: (response) => {
          try {
            observer.next(response)
          }
          catch (e) {
            observer.error(e);
          }
        },
        error: (error) => {
          observer.error(error);
        }
      })
    })
  }

  getCustomerName(paramsReq: object){
    return new Observable((observer) => {
      this.userService.getCustomerName(paramsReq).subscribe({
        next: (response) => {
          observer.next(response);
        },
        error: ((error) => {
          observer.error(error);
        })
      });
    })
  }

  createCustomer(data: UserInput): Observable<any> {
    return new Observable((observer) => {
      this.userService.createCustomer(data).subscribe({
        next: (response) => {
          try {
            observer.next(response)
          }
          catch (e) {
            observer.error(e);
          }
        },
        error: (error) => {
          observer.error(error);
        }
      })
    })
  }

  getCustomerProfileByUserId(id: number): Observable<User> {
    return new Observable((observer) => {
      this.userService.getCustomerProfileByUserId(id).subscribe({
        next: (response: User) => {
          try {
            // if(response.length!=0){
              observer.next(response)
            // }else{
            //   observer.error('Invalid URL');
            // }
          }
          catch (e) {
            observer.error(e);
          }
        },
        error: (error) => {
          observer.error(error);
        }
      })
    })
  }
}




import { MappingPair, Profile } from "@dynamic-mapper/mapper";
import { Client } from "../../models/client/client-list";
import { ClientDTO } from "src/app/domain/client/client.dto";

export class ClientMap extends Profile {
  static readonly DtoToDomain = new MappingPair<Client,ClientDTO>();

  constructor() {
    super();

    this.createMap(ClientMap.DtoToDomain, {
      id: opt => opt.mapFrom(src => src.id),
      userId: opt => opt.mapFrom(src => src.userId),
      clientFirstName: opt => opt.mapFrom(src => src.clientFirstName),
      clientLastName: opt => opt.mapFrom(src => src.clientLastName),
      email: opt => opt.mapFrom(src => src.email),
      images: opt => opt.mapFrom(src => src.images),
      recordStatus: opt => opt.mapFrom(src => src.recordStatus),
      businessName: opt => opt.mapFrom(src => src.businessName),
      businessImage: opt => opt.mapFrom(src => src.businessImage),
      logo: opt => opt.mapFrom(src => src.logo)
    })
  }
}
